<template>
  <div class="w-full flex justify-center">
    <div class="w-full flex flex-col md:flex-row md:justify-between md:items-center gap-5 py-5 px-4 md:p-6 max-w-[1440px]">
      <div class="flex gap-5 order-1 md:order-2">
        <NuxtLink to="https://www.tiktok.com/@tilt.app"><i class="ph-bold ph-tiktok-logo text-neutral-4"/></NuxtLink>
        <NuxtLink to="https://www.instagram.com/tilt"><i class="ph-bold ph-instagram-logo text-neutral-4"/></NuxtLink>
        <NuxtLink to="https://twitter.com/trytilt"><i class="ph-bold ph-twitter-logo text-neutral-4"/></NuxtLink>
        <NuxtLink to="https://discord.com/invite/B6JUMzsTwZ"><i class="ph-bold ph-discord-logo text-neutral-4"/></NuxtLink>
      </div>
      <div class="flex gap-2 md:gap-4 order-2 md:order-1">
        <NuxtLink to="https://support.tilt.app/" class="text-sm text-neutral-4 hover:text-neutral-5 hover:underline">Tilt Support</NuxtLink>
        <NuxtLink to="/terms" class="text-sm text-neutral-4 hover:text-neutral-5 hover:underline">Terms & Conditions</NuxtLink>
        <NuxtLink to="/privacy" class="text-sm text-neutral-4 hover:text-neutral-5 hover:underline">Privacy Policy</NuxtLink>
        <NuxtLink to="/cookies" class="text-sm text-neutral-4 hover:text-neutral-5 hover:underline">Cookie Policy</NuxtLink>
      </div>
    </div>
  </div>
</template>
